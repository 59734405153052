import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
// import { Observable } from 'rxjs/Rx';
// import {map} from 'rxjs/operators';
import { user } from './user';
import { Router } from '@angular/router';
// import 'rxjs/add/operator/map'

@Injectable({
  providedIn: 'root'
})

export class UserService {
  public tempToken:any=[];
  constructor(private http:HttpClient,private _router: Router) { }

  create(user1:user){
    let token=localStorage.getItem('token');
    // let creatUrl="https://dev-oamviewer-api.brainlitz.com/api/userCreate";
    let creatUrl="https://oamviewer-api.brainlitz.com/api/userCreate";
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization:'Bearer'+' '+token
      })
    };
    return this.http.post(creatUrl,user1,httpOptions);
    
    // return this.http.post("http://192.168.0.123:3000/api/userCreate",user1);
  }

  login(email: string,password: string){
    // let loginUrl='https://dev-oamviewer-api.brainlitz.com/api/login'
    let loginUrl='https://oamviewer-api.brainlitz.com/api/login'
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post(loginUrl,{email:email, password:password},httpOptions);
  }

  isLoggedIn(): boolean {
    
    this.tempToken = localStorage.getItem('token');
    console.log(this.tempToken);
    // console.error(this.tempToken);
    if (this.tempToken != null) {
      return true;
    } else {
      localStorage.clear();
      this._router.navigateByUrl('/login');
      return false;
     
    }
  }
}
