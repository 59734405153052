import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { book } from './book';


@Injectable({
  providedIn: 'root'
})
export class BookService {

  constructor(private http: HttpClient) { }

    
  getBookList(): Observable<book[]>{
    let token=localStorage.getItem('token');
    // let getUrl= "https://dev-oamviewer-api.brainlitz.com/api/books";
    let getUrl= "https://oamviewer-api.brainlitz.com/api/books";
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization:'Bearer'+' '+token
      })
    };
    
    return this.http.get<book[]>(getUrl,httpOptions);
    
  }

  
// for delete book
  deleteBook(_id){
    let token=localStorage.getItem('token');
    // let deleteUrl="https://dev-oamviewer-api.brainlitz.com/api/deleteBook/";
    let deleteUrl="https://oamviewer-api.brainlitz.com/api/deleteBook/";
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization:'Bearer'+' '+token
      })
    };
    return this.http.delete(deleteUrl+_id,httpOptions);
    
  }
  //for adding comments
  commentSave(comment,_id){
    console.log(_id)
    let token=localStorage.getItem('token');
    // let importUrl='https://dev-oamviewer-api.brainlitz.com/api/updateBook/';
    let importUrl='https://oamviewer-api.brainlitz.com/api/updateBook/';
    const httpOptions = {
      headers: new HttpHeaders({
        
        Authorization:'Bearer'+' '+token
      })
    };
    var obj ={
      'comment': comment
    }
    return this.http.put(importUrl+_id,obj,httpOptions);
   
  }
  //for adding new book
  addNewBook(ffd){
    let token=localStorage.getItem('token');
    // let importUrl='https://dev-oamviewer-api.brainlitz.com/api/importBook';
    let importUrl='https://oamviewer-api.brainlitz.com/api/importBook';
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization:'Bearer'+' '+token
      })
    };
    return this.http.post(importUrl,ffd,httpOptions);
    
  }
  //for search book
FilterByName(name){
  // let searchUrl="https://dev-oamviewer-api.brainlitz.com/api/searchBook?search=";
  let searchUrl="https://oamviewer-api.brainlitz.com/api/searchBook?search=";
    let token=localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization:'Bearer'+' '+token
      })
};
  return this.http.get(searchUrl+name,httpOptions);
}
  // getImport(){
  //   return this.http.post("http://192.168.0.123:3000/api/importBook");
  // } 
  //deleteBook(bo:book):Observable<book>{
   // return this.http.delete<book>("http://192.168.0.123:3000/api/deleteBook/"+bo._id)
  //}
}
